import React from 'react'
import { Link } from 'gatsby'

const Nav = ({ links }) => {
    return (
        <nav className="relative m-auto z-50">
            <ul className="flex">
                {links.map((link, index) => (
                    <li className="block" key={index}>
                        {link.external && (
                            <a
                                href={link.to}
                                className="block p-2 font-medium text-lg text-shadow text-center tracking-wider"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {link.name}
                            </a>
                        )}
                        {!link.external && (
                            <Link
                                className="block p-2 font-medium text-lg text-shadow text-center tracking-wider"
                                to={link.to}
                            >
                                {link.name}
                            </Link>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default Nav
