import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { imageUrlFor } from '../lib/image-url'
import { buildImageObj } from '../lib/helpers'

const SEO = ({ description, lang, meta, title, image }) => {
    return (
        <StaticQuery
            query={detailsQuery}
            render={data => {
                const metaDescription = description || (data.site && data.site.description) || ''
                const siteTitle = (data.site && data.site.title) || ''
                const metaImage =
                    image && image.asset
                        ? imageUrlFor(buildImageObj(image))
                              .width(1200)
                              .url()
                        : ''

                return (
                    <Helmet
                        htmlAttributes={{ lang }}
                        title={title}
                        titleTemplate={title === siteTitle ? '%s' : `%s | ${siteTitle}`}
                        meta={[
                            {
                                name: 'description',
                                content: metaDescription,
                            },
                            {
                                property: 'og:title',
                                content: title,
                            },
                            {
                                property: 'og:description',
                                content: metaDescription,
                            },
                            {
                                property: 'og:type',
                                content: 'website',
                            },
                            {
                                property: 'og:image',
                                content: metaImage,
                            },
                            {
                                name: 'twitter:card',
                                content: 'summary',
                            },
                            {
                                name: 'twitter:title',
                                content: title,
                            },
                            {
                                name: 'twitter:description',
                                content: metaDescription,
                            },
                        ].concat(meta)}
                    />
                )
            }}
        />
    )
}

SEO.defaultProps = {
    lang: 'en',
    meta: [],
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
            title
            description
        }
    }
`
