import React from 'react'
import PropTypes from 'prop-types'
import { imageUrlFor } from '../lib/image-url'
import { buildImageObj } from '../lib/helpers'
import Nav from './nav'

const Hero = ({ heading, content, image, links }) => (
    <header
        className="relative mb-8 overflow-hidden text-teal-100"
        style={{
            minHeight: '75vh',
        }}
    >
        <div className="px-8 py-4">
            <Nav links={links} />
        </div>

        <div className="relative mx-auto pt-32 max-w-lg z-30">
            <h1 className="font-light text-4xl text-shadow">{heading}</h1>
            {content ? <p>{content}</p> : null}
        </div>

        <div className="hero-transform absolute z-20 inset-x-0 top-0 w-full h-full bg-teal-600 opacity-75"></div>
        <div
            className="hero-transform absolute z-10 top-0 left-0 w-full h-full bg-center bg-cover"
            style={{
                backgroundImage: `url(${imageUrlFor(buildImageObj(image))
                    .maxWidth()
                    .maxHeight()
                    .url()})`,
            }}
        ></div>
        <div className="hero-bg absolute z-0 inset-x-0 top-0 bg-blue-500"></div>
    </header>
)

Hero.propTypes = {
    heading: PropTypes.string,
    content: PropTypes.string,
}

export default Hero
