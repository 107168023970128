import React from 'react'

const HomeContentBlock = ({ IconComponent, heading, content, children }) => (
    <section className="mb-24 max-w-4xl mx-auto">
        <IconComponent className="m-auto text-teal-500" size={32} />
        <h2 className="mb-8 font-light text-4xl text-center tracking-wide">
            {heading}
            <div className="mx-auto mt-3 w-16 h-1 bg-gray-400"></div>
        </h2>
        <p className="leading-8">{content}</p>
        {children}
    </section>
)

export default HomeContentBlock
