import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import '../styles/tailwind.css'

export const query = graphql`
    query PageQuery {
        site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            soundcloud
            email
        }
    }
`

const Layout = ({ children }) => (
    <>
        <Helmet
            bodyAttributes={{
                class: 'leading-relaxed bg-gray-900 text-gray-300',
            }}
        />
        {children}
    </>
)

export default Layout
