import React from 'react'
import ReactPlayer from 'react-player'
import { FaGraduationCap, FaMusic, FaEnvelope } from 'react-icons/fa'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { graphql } from 'gatsby'
import Hero from '../components/hero'
import HomeContentBlock from '../components/homeContentBlock'
import Content from '../components/content'

export const query = graphql`
    query IndexPageQuery {
        site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            title
            description
            soundcloud
            email
        }
        home: sanityHomepage {
            title
            description
            contact {
                content
                heading
            }
            hero {
                heading
                content
                image {
                    asset {
                        _id
                    }
                }
            }
            teaching {
                content
                heading
            }
            demo {
                content
                heading
            }
            song {
                soundcloudURL
            }
        }
    }
`

const IndexPage = ({ data, errors }) => {
    if (errors) {
        return (
            <Layout>
                <GraphQLErrorList errors={errors} />
            </Layout>
        )
    }

    const site = (data || {}).site
    const home = (data || {}).home
    if (!site || !home) {
        throw new Error(
            'Missing "Site settings" or "Homepage". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
        )
    }

    const links = [
        {
            external: true,
            to: site.soundcloud,
            name: 'SoundCloud',
        },
    ]

    return (
        <Layout>
            <SEO title={site.title} description={site.description} />
            <Hero
                heading={home.hero.heading}
                content={home.hero.content}
                image={home.hero.image}
                links={links}
            />
            <Content>
                <HomeContentBlock
                    IconComponent={FaGraduationCap}
                    heading={home.teaching.heading}
                    content={home.teaching.content}
                />
                <HomeContentBlock
                    IconComponent={FaMusic}
                    heading={home.demo.heading}
                    content={home.demo.content}
                >
                    <div className="mt-8">
                        <ReactPlayer url={home.song.soundcloudURL} width="100%" />
                    </div>
                </HomeContentBlock>
                <HomeContentBlock
                    IconComponent={FaEnvelope}
                    heading={home.contact.heading}
                    content={home.contact.content}
                >
                    <div className="mt-8">
                        <a
                            href={`mailto:${site.email}`}
                            className="block mx-auto sm:max-w-sm tracking-wider px-4 py-8 bg-teal-400 text-center text-2xl font-bold text-gray-900"
                        >
                            {site.email}
                        </a>
                    </div>
                </HomeContentBlock>
            </Content>
        </Layout>
    )
}

export default IndexPage
